import image from '../../../../assets/blogImage.jpg'
import {HashLink} from "react-router-hash-link";
const Card = (props) => {
    return(
        <div className="w-full">
            <img className="object-cover w-full max-h-[400px]" src={image} alt="Image"/>
            <h1 className=" mt-[60px] text-[25px] text-[#2F2F2F] font-semibold leading-[130%]">{props?.title}</h1>
            <p className="py-[30px] text-[16px] text-[#2f2f2fb3] font-medium leading-[137%]">{props?.description}</p>
            <div className="flex items-center justify-between w-full">
                <p className="font-medium text-[14px] text-[#2f2f2f80]">
                    {props?.date}
                </p>
                <HashLink to={`/blog/${props?.id}/#`}>
                    <p className="underline text-[16px] text-[#123D21] font-semibold leading-[137%] cursor-pointer">
                        Dowiedz się więcej
                    </p>
                </HashLink>
            </div>
        </div>
    )

}

export default Card;