import aboutusImage from '../../../assets/aboutusImage.jpg'
import Card from "./card/Card";
const Descriptions = () => {

    const tab = [
        {
            title: "O nas 1",
            description: "O nas 1",
            image: aboutusImage
        },
        {
            title: "O nas 2",
            description: "O nas 2",
            image: aboutusImage
        },
        {
            title: "O nas 3",
            description: "O nas 3",
            image: aboutusImage
        }
    ]

    return (
        <div className="container mx-auto">

            {
                tab?.map((text, index) => (
                    <div className="py-[100px] md:my-[50px]">
                        <Card title={text?.title} description={text?.description} image={text?.image} index={index}></Card>
                    </div>
                ))
            }
            
        </div>
    )
}

export default Descriptions;