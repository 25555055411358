import Card from "./card/Card";
import {HashLink} from "react-router-hash-link";

const Blog = (props) => {


    return(
        <section id="blog">
            <div className="container mx-auto py-[100px]">
                <h1 className="mx-auto text-center text-[35px] font-medium text-[#2F2F2F] max-w-[362px] uppercase leading-[132%]">
                    Najnowsze wpisy z
                    naszego bloga
                </h1>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[30px] xl:gap-[80px] py-[50px]">
                    {
                        props?.blogs?.map((article, index) => (
                            <Card title={article?.attributes?.title} description={article?.attributes?.description} date={article?.attributes?.date} image={article?.attributes?.image} id={article?.id} key={index} />
                        ))
                    }
                </div>
                <div className="flex items-center justify-center">
                    <HashLink to="/wszystkie-blogi/#">
                        <button className="w-full md:w-auto px-[30px] py-[18px] bg-[#212322] rounded-[3px] text-[#FFF] text-[16px] font-semibold font-poppins leading-[137%]">
                            Sprawdź wszystkie posty
                        </button>
                    </HashLink>
                </div>
            </div>
        </section>

    )

}

export default Blog;