import {useEffect, useState} from "react";
import offer1Image from "../../../assets/offer/offer1.svg";

const Offer = () => {

    const options = [
        {
            title: "Postępowania sądowe",
            description: "Sed quis velit ac urna dapibus laoreet. Sed ut velit non leo vestibulum mollis et sit amet quam. In laoreet vulputate lacus quis iaculis. Vestibulum mi orci, pulvinar at commodo sit amet, pharetra vel urna. Cras luctus sit amet turpis non hendrerit. Donec diam risus, mollis dignissim arcu ut, gravida tristique nisi. Donec pulvinar luctus nisi luctus imperdiet.",
            image: offer1Image
        },
        {
            title: "Postępowania sądowe 1",
            description: "Sed quis velit ac urna dapibus laoreet. Sed ut velit non leo vestibulum mollis et sit amet quam. In laoreet vulputate lacus quis iaculis. Vestibulum mi orci, pulvinar at commodo sit amet, pharetra vel urna. Cras luctus sit amet turpis non hendrerit. Donec diam risus, mollis dignissim arcu ut, gravida tristique nisi. Donec pulvinar luctus nisi luctus imperdiet.",
            image: offer1Image
        },
    ]
    const [showMenu , setShowMenu] = useState(false);
    const [menuIndex , setMenuIndex] = useState(0);

    const menuClick = (index) => {
        setShowMenu(!showMenu);
        setMenuIndex(index);
    }




    return(
        <section id="oferta">

            <div className="md:container mx-auto px-[10px]">
                <div className="bg-[#212322] rounded-[3px] p-[30px] lg:p-[80px]">

                    <div className="flex flex-col lg:flex-row items-center justify-between w-full ">
                        <h1 className="text-[40px] text-[#FFF] max-w-[290px] text-center lg:text-left">Czym się zajmujemy?</h1>
                        <div className="relative">
                            <div className="px-[35px] py-[24px] flex items-center border border-[#FFF] mt-[30px] lg:mt-0 cursor-pointer z-[600]" onClick={()=>setShowMenu(!showMenu)}>
                                <p className="mr-[10px] text-[16px] text-[#FFF] font-semibold">
                                    {options[menuIndex]?.title}
                                </p>
                                <svg xmlns="http://www.w3.org/2000/svg" width="7" height="6" viewBox="0 0 7 6" fill="none">
                                    <path d="M4.24061 5.18369C3.84375 5.62111 3.15625 5.62111 2.75939 5.18369L0.253744 2.42193C-0.32927 1.77932 0.12669 0.75 0.994358 0.75L6.00564 0.749999C6.87331 0.749999 7.32927 1.77933 6.74625 2.42193L4.24061 5.18369Z" fill="white" fill-opacity="0.7"/>
                                </svg>
                            </div>
                            {
                                showMenu && (
                                    <div className="w-full h-full absolute z-50 bg-[#FFF]">
                                        {
                                            options.map((option,index) => (
                                                <div className="w-full bg-[#FFF] py-[24px] px-[35px] text-[16px] hover:font-semibold cursor-pointer" onClick={()=> menuClick(index)} key={index}>
                                                    {option?.title}
                                                </div>
                                            ))
                                        }
                                    </div>
                                )
                            }
                        </div>


                    </div>

                    <div className="grid grid-cols-1 lg:grid-cols-2 mt-[50px] h-full gap-[25px] xl:gap-[50px]">

                        <div className="flex flex-col justify-center text-center lg:text-left h-full lg:order-first">
                            <h1 className="text-[30px] text-[#FFF] font-semibold">
                                {options[menuIndex]?.title}
                            </h1>
                            <p className="py-[50px] text-[#ffffffb3] text-[16px] font-medium">
                                {options[menuIndex]?.description}
                            </p>
                            <div className="">
                                <button className="py-[20px] px-[35px] bg-[#FFF] rounded-[3px] text-[#123D21] text-[16px] font-semibold border border-[#333]">
                                    Dowiedz się więcej
                                </button>
                            </div>
                        </div>
                        <div className="w-full h-full order-first z-[20] bg-transparent">
                            <img src={options[menuIndex]?.image} className="w-full h-full object-cover" alt="Image"/>
                        </div>
                    </div>
                </div>


            </div>

        </section>
    )

}

export default Offer;