import aboutUsImage from "../../../../assets/aboutusImage.jpg"
const Image = () => {
    return(
        <div className="w-full h-full">
            <img src={aboutUsImage} alt="Image" className="object-cover w-full h-full rounded-[10px]"/>
        </div>
    )

}

export default Image;