import Left from "./left/Left";
import Right from "./right/Right";

const AboutUs = () => {
    return(
        <div className="container mx-auto py-[80px]">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-[30px] lg:gap-[100px] ">
                <Left/>
                <Right/>
            </div>
        </div>
    )
}

export default AboutUs;