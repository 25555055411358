const Left = () => {
    return(
        <div className="text-center xl:text-left ">

            <div className="flex items-center text-center xl:text-left justify-center xl:justify-start">
                <div className="h-[14px] w-[14px] rounded-[2px] bg-[#123D21]"></div>
                <p className="text-[12px] md:text-[14px] font-medium text-[#2F2F2F] ml-[12px] uppercase leading-[137%] text-justify">Adwokat Marzena Staciwa</p>
            </div>

            <h1 className="text-[45px] lg:text-[64px] text-[#2F2F2F] font-medium py-[45px] leading-[120%]">
                Kancelaria prawa
                procesowego
            </h1>

            <p className="text-[16px] text-[#2f2f2fb3] font-medium leading-[137%] max-w-[650px] mx-auto xl:mx-0">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent auctor ullamcorper velit, a facilisis felis malesuada id ut cursus.
            </p>

            <div className="pt-[50px]">
                <button className="px-[30px] py-[18px] bg-[#123D21] rounded-[3px] text-[#FFF] text-[16px] font-semibold font-poppins leading-[137%]">
                    Zacznij działać
                </button>
            </div>

            <div className=" mt-[80px] flex items-center justify-center xl:justify-start" data-aos="fade-right" data-aos-delay="300">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="32" viewBox="0 0 22 32" fill="none">
                    <rect x="1" y="1" width="20" height="30" rx="10" stroke="#2F2F2F" stroke-opacity="0.3" stroke-width="2"/>
                    <path d="M11 7L11 11" stroke="#1364EE" stroke-width="2" stroke-linecap="round"/>
                </svg>
                <p className="max-w-[110px] text-[12px] text-[#2f2f2f4d] font-poppins font-medium ml-[14px]">
                    Zjedź w dół po
                    więcej informacji
                </p>
            </div>

        </div>
    )
}

export default Left;