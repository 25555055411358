import Left from "./left/Left";
import Right from "./right/Right";
import headerImage from "../../../assets/headerImage.svg";

const Header = () => {
    return(
        <section id="" className="bg-[#EFEFEF]">

            <div className="py-[80px] container mx-auto">
                <div className="grid grid-cols-1 xl:grid-cols-2">
                    <div className="" data-aos="fade-right">
                        <Left/>
                    </div>
                    <div className="hidden xl:block relative" data-aos="fade-left">
                        <Right/>

                    </div>
                </div>
            </div>

        </section>
    )

}

export default Header;