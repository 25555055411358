import {BrowserRouter, Route, Routes} from "react-router-dom";
import {useEffect, useState} from "react";
import AOS from "aos";
import 'aos/dist/aos.css';
import axios from "./axios";

import Navbar from "./components/navbar/Navbar";
import Header from "./components/home/header/Header";
import HomeAboutus from "./components/home/aboutus/AboutUs";
import HomeOffer from "./components/home/offer/Offer";
import HomeBlogs from "./components/home/blog/Blog";
import Footer from "./components/footer/Footer";
import Blogs from "./components/blogs/Blogs";
import Article from "./components/blogs/article/Article";
import AboutUs from "./components/aboutus/Aboutus";
import Contact from "./components/contact/Contact";

function App() {
    const [blogs, setBlogs] = useState();
    const fetchBlogs = async () => {
        try {
            const response = await axios.get("/blogs?populate=content");

            setBlogs(response?.data?.data)
        }catch (error){
            console.log(error);
        }
    }
    useEffect(() => {
        fetchBlogs();

        AOS.init({
            once: true,
            delay: 0,
            duration: 600
        });

    }, [])


    return (
        <div className="App">
          <BrowserRouter>
            <Routes>
              <Route path="*" element={[<Navbar/> , <Header/>, <HomeAboutus/>, <HomeOffer/> , <HomeBlogs blogs={blogs}/>, <Footer/>]}></Route>
              <Route path="/o-nas" element={[<Navbar/> , <AboutUs/>, <HomeOffer/> , <HomeBlogs blogs={blogs}/>, <Footer/>]}></Route>
              <Route path="/kontakt" element={[<Navbar/> , <Contact/>, <Footer/>]}></Route>
              <Route path="/wszystkie-blogi" element={[<Navbar/> , <Blogs blogs={blogs}/>, <Footer/>]}></Route>
              <Route path="/blog/:id" element={[<Navbar/> ,<Article/>, <Footer/>]}></Route>
            </Routes>
          </BrowserRouter>
        </div>
    );
}

export default App;
