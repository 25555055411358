import Header from "./header/Header";
import Descriptions from "./descriptions/Descriptions";
const AboutUs = () => {
    return(
        <div>
            <Header/>
            <Descriptions/>
        </div>
    )
}

export default AboutUs;