import axios from "axios";


const instance = axios.create({
    baseURL: 'https://staciwa-adwokat.pl/strapi/api',
    timeout: 5000,
    headers: {
        'Authorization': `Bearer c535ceabd2db98afdf7dada54e9c6199e99542d38ce68e9befea8b849c44f524cad45591d39af0472cc4fb4811b904da5c5b7de24c4f422325b71c1c2043a87d0add9b4a49a8f4ea749c128f43fb445eb387087e1990cdcd95ddee53f38d0879bbfefba4626009da56510c4b22a692ccc82aa0ca3c702adc3f37b67b2e86bc4e`,
        'Content-Type': 'application/json',
    }
});

export default instance;