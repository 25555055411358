import {HashLink} from "react-router-hash-link";

const Desc = () => {
    return(
        <div className="text-center lg:text-left">
            <h1 className="text-[35px] text-[#2F2F2F] max-w-[350px] mx-auto lg:mx-0">Pracujemy tak, aby zapewnić Ci sukces</h1>
            <p className="py-[50px] text-[#2f2f2fb3] text-[16px] font-medium leading-[137%]">
                Sed quis velit ac urna dapibus laoreet. Sed ut velit non leo vestibulum mollis et sit amet quam. In laoreet vulputate lacus quis iaculis. Vestibulum mi orci, pulvinar at commodo sit amet, pharetra vel urna. Cras luctus sit amet turpis non hendrerit. Donec diam risus, mollis dignissim arcu ut, gravida tristique nisi. Donec pulvinar luctus nisi luctus imperdiet.
            </p>

            <div className="grid grid-cols-1 xl:grid-cols-2 gap-y-[40px] gap-x-[20px]">

                <div className="flex flex-col lg:flex-row items-center justify-center lg:justify-start gap-[40px]">
                    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="39" viewBox="0 0 35 39" fill="none">
                        <path d="M34.5333 27.1826V2.36178C34.5333 1.37929 33.7085 0.588867 32.6833 0.588867H7.4C3.31458 0.588867 0 3.76534 0 7.68053V31.3194C0 35.2346 3.31458 38.4111 7.4 38.4111H32.6833C33.7085 38.4111 34.5333 37.6207 34.5333 36.6382V35.4562C34.5333 34.9022 34.2635 34.3999 33.8473 34.0748C33.5235 32.9372 33.5235 29.6943 33.8473 28.5566C34.2635 28.239 34.5333 27.7367 34.5333 27.1826ZM9.86667 10.4877C9.86667 10.2439 10.0748 10.0444 10.3292 10.0444H26.6708C26.9252 10.0444 27.1333 10.2439 27.1333 10.4877V11.9651C27.1333 12.2089 26.9252 12.4083 26.6708 12.4083H10.3292C10.0748 12.4083 9.86667 12.2089 9.86667 11.9651V10.4877ZM9.86667 15.2154C9.86667 14.9717 10.0748 14.7722 10.3292 14.7722H26.6708C26.9252 14.7722 27.1333 14.9717 27.1333 15.2154V16.6929C27.1333 16.9366 26.9252 17.1361 26.6708 17.1361H10.3292C10.0748 17.1361 9.86667 16.9366 9.86667 16.6929V15.2154ZM29.3996 33.6833H7.4C6.03562 33.6833 4.93333 32.6269 4.93333 31.3194C4.93333 30.0193 6.04333 28.9555 7.4 28.9555H29.3996C29.2531 30.2187 29.2531 32.4201 29.3996 33.6833Z" fill="#123D21"/>
                    </svg>
                    <div>
                        <h1 className="text-[20px] text-[#2F2F2F] font-semibold">Kompetencje</h1>
                        <p className="mt-[10px] text-[16px] font-medium text-[#2f2f2fb3]">Duis in metus ut orci malesuada dolor melislacinia.</p>
                    </div>
                </div>

                <div className="flex flex-col lg:flex-row items-center justify-center lg:justify-start gap-[40px]">
                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="30" viewBox="0 0 40 30" fill="none">
                        <path d="M23.7064 1.875H31.5208C32.6014 1.875 33.4744 2.71289 33.4744 3.75C33.4744 4.78711 32.6014 5.625 31.5208 5.625H24.5855C24.2681 7.13672 23.1875 8.38477 21.7528 8.98242V26.25H31.5208C32.6014 26.25 33.4744 27.0879 33.4744 28.125C33.4744 29.1621 32.6014 30 31.5208 30H19.7992H8.0776C6.99701 30 6.12399 29.1621 6.12399 28.125C6.12399 27.0879 6.99701 26.25 8.0776 26.25H17.8456V8.98242C16.4109 8.37891 15.3303 7.13086 15.0129 5.625H8.0776C6.99701 5.625 6.12399 4.78711 6.12399 3.75C6.12399 2.71289 6.99701 1.875 8.0776 1.875H15.892C16.7833 0.738281 18.1997 0 19.7992 0C21.3987 0 22.8151 0.738281 23.7064 1.875ZM27.1008 18.75H35.9408L31.5208 11.4727L27.1008 18.75ZM31.5208 24.375C27.6808 24.375 24.4878 22.3828 23.8285 19.752C23.6698 19.1074 23.8896 18.4453 24.2375 17.8711L30.0495 8.30859C30.3548 7.80469 30.9164 7.5 31.5208 7.5C32.1252 7.5 32.6869 7.81055 32.9921 8.30859L38.8041 17.8711C39.1521 18.4453 39.3718 19.1074 39.2131 19.752C38.5538 22.3828 35.3609 24.375 31.5208 24.375ZM8.00434 11.4727L3.58431 18.75H12.4305L8.00434 11.4727ZM0.318135 19.752C0.159405 19.1074 0.379185 18.4453 0.727171 17.8711L6.53913 8.30859C6.84438 7.80469 7.40604 7.5 8.01044 7.5C8.61484 7.5 9.1765 7.81055 9.48175 8.30859L15.2937 17.8711C15.6417 18.4453 15.8615 19.1074 15.7027 19.752C15.0373 22.3828 11.8444 24.375 8.00434 24.375C4.16429 24.375 0.977476 22.3828 0.318135 19.752Z" fill="#123D21"/>
                    </svg>
                    <div>
                        <h1 className="text-[20px] text-[#2F2F2F] font-semibold">Etyka pracy</h1>
                        <p className="mt-[10px] text-[16px] font-medium text-[#2f2f2fb3]">Duis in metus ut orci malesuada dolor melislacinia.</p>
                    </div>
                </div>

                <div className="flex flex-col lg:flex-row items-center justify-center lg:justify-start gap-[40px]">
                    <svg xmlns="http://www.w3.org/2000/svg" width="37" height="37" viewBox="0 0 37 37" fill="none">
                        <path d="M37 0C33.258 0.257267 6.969 2.76056 5.13127 20.7844C4.90797 22.711 4.7815 24.6463 4.6984 26.583L17.6247 13.6698C18.0763 13.2182 18.8098 13.2182 19.2615 13.6698C19.7132 14.1215 19.7132 14.8535 19.2615 15.3052L0.508394 34.0394C-0.169465 34.7166 -0.169465 35.815 0.508394 36.4921C1.18625 37.1693 2.28542 37.1693 2.964 36.4921L7.09258 32.3679C10.1343 32.3578 13.1738 32.1851 16.1952 31.8361C20.06 31.4429 23.2065 29.9232 25.7763 27.7501H18.481L29.089 24.2178C29.902 23.1417 30.6333 21.9985 31.2895 20.8126H25.4258L33.1251 16.9673C36.1538 9.60126 36.8692 1.8977 37 0Z" fill="#123D21"/>
                    </svg>
                    <div>
                        <h1 className="text-[20px] text-[#2F2F2F] font-semibold">Doświadczenie</h1>
                        <p className="mt-[10px] text-[16px] font-medium text-[#2f2f2fb3]">Duis in metus ut orci malesuada dolor melislacinia.</p>
                    </div>
                </div>

                <div className="flex flex-col lg:flex-row items-center justify-center lg:justify-start gap-[40px]">
                    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="34" viewBox="0 0 23 34" fill="none">
                        <path d="M6.27665 30.1711C6.2773 30.5888 6.39883 30.9978 6.62687 31.3458L7.74355 33.0517C7.93431 33.3434 8.19305 33.5825 8.49674 33.748C8.80043 33.9134 9.13966 34 9.48423 34H13.5164C13.861 34 14.2002 33.9134 14.5039 33.748C14.8076 33.5825 15.0663 33.3434 15.2571 33.0517L16.3738 31.3458C16.6017 30.9978 16.7235 30.5892 16.724 30.1711L16.7266 27.6245H6.27338L6.27665 30.1711ZM0 11.6873C0 14.6337 1.07486 17.3218 2.84625 19.3757C3.92568 20.6274 5.61409 23.2424 6.2577 25.4484C6.26031 25.4657 6.26227 25.4829 6.26489 25.5002H16.7351C16.7377 25.4829 16.7397 25.4663 16.7423 25.4484C17.3859 23.2424 19.0743 20.6274 20.1537 19.3757C21.9251 17.3218 23 14.6337 23 11.6873C23 5.22014 17.8322 -0.0198648 11.4641 5.66153e-05C4.79864 0.0206421 0 5.50967 0 11.6873ZM11.5 6.37492C8.61781 6.37492 6.27273 8.75819 6.27273 11.6873C6.27273 12.2743 5.80489 12.7498 5.22727 12.7498C4.64966 12.7498 4.18182 12.2743 4.18182 11.6873C4.18182 7.58615 7.46455 4.24997 11.5 4.24997C12.0776 4.24997 12.5455 4.72543 12.5455 5.31244C12.5455 5.89946 12.0776 6.37492 11.5 6.37492Z" fill="#123D21"/>
                    </svg>
                    <div>
                        <h1 className="text-[20px] text-[#2F2F2F] font-semibold">Zrozumienie</h1>
                        <p className="mt-[10px] text-[16px] font-medium text-[#2f2f2fb3]">Duis in metus ut orci malesuada dolor melislacinia.</p>
                    </div>
                </div>
            </div>

            <div className="mt-[70px] flex flex-col md:flex-row items-center justify-center lg:justify-start gap-[20px] w-full">
                <HashLink to="/kontakt/#">
                    <button className="w-full lg:w-auto px-[30px] py-[18px] bg-[#212322] rounded-[3px] text-[#FFF] text-[16px] font-semibold font-poppins leading-[137%]">
                        Zacznij działać
                    </button>
                </HashLink>
                <HashLink to="/o-nas/#">
                    <button className="w-full lg:w-auto px-[30px] py-[18px] rounded-[3px] text-[#2f2f2fb3] text-[16px] font-semibold font-poppins leading-[137%]">
                        Dowiedz się więcej
                    </button>
                </HashLink>
            </div>
        </div>
    )

}

export default Desc;