import Image from "./right/Image";
import Desc from "./left/Desc";

const AboutUs = () => {
    return(
        <section id="o-nas">
            <div className="container mx-auto py-[100px]">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-[50px] xl:gap-[100px]">
                    <div className="" data-aos="fade-right">
                        <Image/>
                    </div>
                    <div data-aos="fade-left">
                        <Desc/>
                    </div>
                </div>

            </div>
        </section>
    )

}

export default AboutUs;