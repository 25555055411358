import Card from "../home/blog/card/Card";
import blog1Image from "../../assets/blog/blogImage1.svg";
import {HashLink} from "react-router-hash-link";

const Blogs = (props) => {

    return (
        <div className="container mx-auto py-[160px]">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[50px]">
                {props?.blogs?.map((post, index) =>(
                    <HashLink to={`/blog/${post?.id}/#`} key={index}>
                        <Card title={post?.attributes?.title} description={post?.attributes?.description} date={post?.attributes?.date} image={blog1Image} id={post?.id} key={index}/>
                    </HashLink>
                ))}
            </div>
        </div>
    )
}

export default Blogs;