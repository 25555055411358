const Card = (props) => {
    return(
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-[50px] lg:gap-[150px]">
            <div className={props?.index %2 === 0 ? "lg:order-first" : "lg:order-last"}>
                <img src={props?.image} alt="Image" className="w-full h-full object-cover"/>
            </div>
            <div className="flex flex-col justify-center">
                <h1 className="text-[42px] text-[#2F2F2F] font-semibold leading-[130%] text-center lg:text-left">{props?.title}</h1>
                <p className="text-[16px] font-poppins text-[#2f2f2fb3] leading-[137%] mt-[50px] text-center lg:text-left">{props?.description}</p>
            </div>
        </div>
    )

}

export default Card;