import logoImage from "../../assets/ZnakImage.png"
import {HashLink} from "react-router-hash-link";
import {useEffect, useState} from "react";
import {AiOutlineClose, AiOutlineMenu} from "react-icons/ai";

const links = [
    {
        title: "Strona głowna",
        link: "/#"
    },
    {
        title: "O nas",
        link: "/#o-nas"
    },
    {
        title: "Oferta",
        link: "/#oferta"
    },
    {
        title: "Aktualności",
        link: "/#blog"
    },
]

const Navbar = () => {

    const [nav, setNav] = useState(false);
    const [scrolling, setScrolling] = useState(false)

    const handleNav = () => {
        setNav(!nav);
    };

    const handleScroll = () => {
        if(window.scrollY > 60) {
            setScrolling(true);
        }else {
            setScrolling(false)
        }
    }

    useEffect(() => {
        window.addEventListener("scroll", handleScroll)

        return () => {
            window.removeEventListener("scroll", handleScroll)
        }

    }, [])

    return(
        <div className={scrolling ? "shadow-2xl navbar w-full fixed z-[500] bg-[#FFF] top-0" : "bg-[#EFEFEF] navbar w-full z-[500] top-0"}>
            <div className='container mx-auto p-[20px] flex justify-between items-center w-full'>
                <div className="w-full flex items-center justify-between">
                    <HashLink to="/#">
                        <img src={logoImage} alt="Logo" className="h-[80px] w-[80px]"/>
                    </HashLink>

                    <ul className='hidden lg:flex lg:items-center text-[12px] text-[#2B343F]'>
                        {links.map((link) => (
                            <HashLink to={link?.link}>
                                <li className="mx-[16px] text-[16px] text-[#2f2f2fb3] font-poppins font-medium leading-[137%] hover:text-[#2F2F2F] hover:font-semibold py-[20px]">
                                    {link?.title}
                                </li>
                            </HashLink>
                        ))}
                    </ul>
                    <div className="flex items-center justify-center flex-row hidden lg:flex">
                        <HashLink to="/kontakt/#">
                            <button className="px-[30px] py-[18px] bg-[#123D21] rounded-[3px] text-[#FFF] text-[16px] font-semibold font-poppins leading-[137%]">
                                Skontaktuj się
                            </button>
                        </HashLink>
                    </div>


                    <div onClick={handleNav} className='block lg:hidden'>
                        <AiOutlineMenu size={30} className="text-[#000] cursor-pointer" />
                    </div>
                </div>
                <ul className={nav ? 'fixed left-0 top-0 w-full h-full bg-[#FDFDFD] ease-in-out duration-500 z-[500] mx-auto py-[20px]' : 'ease-in-out duration-500 fixed left-[-100%]'} onClick={() => setNav(false)}>
                    <div className="flex items-center justify-around">
                        <HashLink to="/#">
                            <img src={logoImage} alt="Logo" className="h-[60px] w-[60px]"/>
                        </HashLink>
                        <AiOutlineClose size={30} className="text-[#000] cursor-pointer" onClick={() => setNav(false)}/>
                    </div>
                    <div className="text-center py-[30px]">
                        {links.map((link) => (
                            <HashLink to={link?.link}>
                                <li className="mx-[16px] text-[16px] text-[#2f2f2fb3] font-poppins font-medium leading-[137%] hover:text-[#2F2F2F] hover:font-semibold py-[20px]">
                                    {link?.title}
                                </li>
                            </HashLink>
                        ))}
                    </div>
                    <div className="flex flex-col items-center">
                        <HashLink to="/kontakt/#">
                            <button className="px-[30px] py-[18px] bg-[#123D21] rounded-[3px] text-[#FFF] text-[16px] font-semibold font-poppins leading-[137%]">
                                Skontaktuj się
                            </button>
                        </HashLink>
                    </div>
                </ul>
            </div>
        </div>
    )
}

export default Navbar;