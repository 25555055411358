import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import axios from "../../../axios";
import Card from "./card/Card";

import image from '../../../assets/blog/blogImage1.svg'

const Article = () => {
    const [blog, setBlog] = useState([null]);
    const { id } = useParams();

    const fetchBlog = async () => {
        try {
            const response = await axios.get(`/blogs/${id}?populate=content`);

            setBlog(response?.data?.data)

        }catch (error){
            console.log(error);
        }
    }
    useEffect(() => {
        fetchBlog();

    }, [])

    return(
        <div className="container mx-auto py-[160px] overflow-x-hidden">
            <div className="grid grid-cols-1 gap-[150px] lg:gap-[100px]">
                {blog?.attributes?.content.map((article, index) =>(
                    <Card title={article?.title} description={article?.longContent} image={image} index={index}/>
                ))}
            </div>
        </div>
    )
}

export default Article;