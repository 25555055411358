import logo from "../../assets/ZnakImage.png"
import {HashLink} from "react-router-hash-link";
const Footer = () => {
    return(
        <div className="bg-[#212322]">
            <div className="container mx-auto py-[70px]">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-y-[40px] gap-x-[30px]">

                    <div className="lg:col-span-2 flex flex-col mx-auto lg:mx-0 text-center lg:text-left" data-aos="fade-right" data-aos-delay="400">
                        <div className="flex items-center">
                            <img src={logo} alt="Logo" className="w-[60px] h-[60px]"/>
                            <h1 className="text-[20px] text-[#FFF] font-medium leading-[132%] uppercase ml-[25px]">
                                Kancelaria Adwokacka <br/> <span className="normal-case">adwokat Marzena Staciwa</span>
                            </h1>
                        </div>
                        <p className="max-w-[400px] py-[40px] text-[14px] font-medium text-[#ffffffb3]">
                            W codziennej pracy łączymy doświadczenie zdobyte podczas praktyki prawa cywilnego oraz gospodarczego ze znajomością prawa karnego.
                        </p>
                        <p className="text-[#ffffff33] text-[14px] font-medium leading-[137%]">
                            Wszelkie prawa zastrzeżone.
                        </p>
                        <p className="text-[#ffffff80] text-[14px] font-semibold leading-[137%] mt-[15px]">
                            <span className="font-medium">© 2023</span> staciwa-adwokat.pl All rights reserved
                        </p>
                        <p className="text-[#ffffff80] text-[14px] font-semibold leading-[137%] mt-[30px] cursor-pointer" onClick={() => window.open("https://envcode.pl/")}>
                            <span className="font-medium text-[#ffffff33]">Website made by:</span> Envcode.pl
                        </p>
                    </div>

                    <div className="mx-auto lg:mx-0 text-center lg:text-left order-first md:order-none" data-aos="fade-right" data-aos-delay="300">
                        <h1 className="text-[16px] text-[#FFF] font-semibold leading-[132%]">
                            Szybkie linki
                        </h1>
                        <div className="flex flex-col gap-[25px] mt-[40px]">
                            <HashLink to="/#">
                                <p className="text-[14px] font-medium text-[#ffffffb3]">
                                    Strona główna
                                </p>
                            </HashLink>

                            <HashLink to="/#o-nas">
                                <p className="text-[14px] font-medium text-[#ffffffb3]">
                                    O nas
                                </p>
                            </HashLink>

                            <HashLink to="/#oferta">
                                <p className="text-[14px] font-medium text-[#ffffffb3]">
                                    Oferta
                                </p>
                            </HashLink>

                            <HashLink to="/#blog">
                                <p className="text-[14px] font-medium text-[#ffffffb3]">
                                    Aktualności
                                </p>
                            </HashLink>

                            <HashLink to="/#">
                                <p className="text-[14px] font-medium text-[#ffffffb3]">
                                    Kontakt
                                </p>
                            </HashLink>
                        </div>
                    </div>

                    <div className="hidden lg:block mx-auto lg:mx-0 text-center lg:text-left" data-aos="fade-right" data-aos-delay="200">
                        <h1 className="text-[16px] text-[#FFF] font-semibold leading-[132%]">
                            Czym się zajmujemy?
                        </h1>
                        <div className="flex flex-col gap-[25px] mt-[40px]">
                            <p className="max-w-[180px] text-[14px] font-medium text-[#ffffffb3]">
                                Postępowania sądowe - karne i cywilne
                            </p>
                            <p className="max-w-[180px] text-[14px] font-medium text-[#ffffffb3]">
                                Doradztwo korporacyjne
                            </p>
                            <p className="max-w-[180px] text-[14px] font-medium text-[#ffffffb3]">
                                Doradztwo transakcyjne
                            </p>
                            <p className="max-w-[180px] text-[14px] font-medium text-[#ffffffb3]">
                                Przygotowanie i opiniowanie umów
                            </p>
                        </div>
                    </div>

                    <div className="hidden lg:block mx-auto lg:mx-0 text-center lg:text-left" data-aos="fade-right" data-aos-delay="100">
                        <h1 className="text-[16px] text-[#FFF] font-semibold leading-[132%]">
                            Komu pomagamy?
                        </h1>
                        <div className="flex flex-col gap-[25px] mt-[40px]">
                            <p className="max-w-[250px] text-[14px] font-medium text-[#ffffffb3]">
                                Kancelaria oferuje kompleksową obsługę prawną firmom i instytucjom oraz klientom indywidualnym.
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Footer;