const Right = () => {

    return(
        <div className="my-auto">
            <h1 className="font-poppins font-medium text-[40px] text-[#2F2F2F] max-w-[380px] leading-[137%]">Dowiedz się więcej <span className="font-semibold text-[#123D21]">o mnie</span> </h1>

            <p className="mt-[50px] font-poppins font-medium text-[16px] text-[#2F2F2FB2] leading-[137%]">Sed quis velit ac urna dapibus laoreet. Sed ut velit non leo vestibulum mollis et sit amet quam. In laoreet vulputate lacus quis iaculis. Vestibulum mi orci, pulvinar at commodo sit amet, pharetra vel urna. Cras luctus sit amet turpis non hendrerit. Donec diam risus, mollis dignissim arcu ut, gravida tristique nisi. Donec pulvinar luctus nisi luctus imperdiet.</p>
            <p className="mt-[30px] font-poppins font-medium text-[16px] text-[#2F2F2FB2] leading-[137%]">Vivamus interdum purus eu tempor lobortis. Nulla lobortis lacus sit amet fermentum iaculis. Phasellus sed dapibus odio. Mauris rhoncus ipsum at sagittis pulvinar. Donec non ultricies sem.</p>

            <div className="flex flex-col lg:flex-row mt-[60px]">
                <button className="px-[30px] py-[18px] bg-[#123D21] rounded-[3px] text-[#FFF] text-[16px] font-semibold font-poppins leading-[137%]">
                    Zacznij działać
                </button>

                <button className="lg:ml-[20px] px-[35px] py-[24px] rounded-[10px] text-[#123D21] font-semibold text-[16px] font-poppins">
                    Zacznij działać
                </button>
            </div>
        </div>
    )
}

export default Right;