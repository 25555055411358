// import headerImage from "../../../../assets/headerImage.svg"

const Right = () => {
    return(
        <div className="w-full h-full relative flex items-center justify-center">
            <div className="w-[80%] h-full rounded-tr-[300px] bg-header"></div>
            {/*<img src={headerImage} className="w-full min-h-full absolute xl:top-0 2xl:-top-[15%] right-[2%] z-50" alt="img"/>*/}
        </div>
    )
}

export default Right;