
const Contact = () => {

    const mapUrl = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2438.911197672814!2d21.11231911339846!3d52.317613871892114!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471ecfa86d9cb767%3A0x7470e68d91619e1a!2sFabryczna%2089%2C%2005-270%20Marki!5e0!3m2!1spl!2spl!4v1704413990165!5m2!1spl!2spl";

    return (
        <div className="container mx-auto py-[120px]">
            <div className="text-center max-w-[600px] mx-auto">
                <p className="text-[#1364EE] text-[14px] font-semibold">Chętnie z Tobą porozmawiamy</p>
                <h1 className="text-[45px] text-[#2F2F2F] font-semibold py-[20px]">Skontaktuj się z nami</h1>

                <p className="py-[20px]">Masz jakieś pytania? Jesteśmy do Twoich usług! Wyślij do nas wiadomość za pomocą formularza, a nasz zespół obsługi klienta skontaktuje się z Tobą jak najszybciej. Możesz również wybrać drogę telefoniczną. Do usłyszenia!</p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 py-[20px]">
                <div className="flex items-center justify-center flex-col text-center md:flex-row md:text-left">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="11" viewBox="0 0 20 11" fill="none">
                        <path d="M19.6211 3.40935C19.7734 3.32599 20 3.40397 20 3.53572V9.03423C20 9.74675 19.1602 10.3248 18.125 10.3248H1.875C0.839844 10.3248 0 9.74675 0 9.03423V3.53841C0 3.40397 0.222656 3.32868 0.378906 3.41203C1.25391 3.87988 2.41406 4.47409 6.39844 6.46646C7.22266 6.88053 8.61328 7.75169 10 7.74631C11.3945 7.75438 12.8125 6.8644 13.6055 6.46646C17.5898 4.47409 18.7461 3.87719 19.6211 3.40935ZM10 6.88322C10.9062 6.89398 12.2109 6.0981 12.8672 5.77008C18.0508 3.1808 18.4453 2.95495 19.6406 2.30964C19.8672 2.18865 20 2.00044 20 1.80147V1.2906C20 0.578083 19.1602 0 18.125 0H1.875C0.839844 0 0 0.578083 0 1.2906V1.80147C0 2.00044 0.132812 2.18596 0.359375 2.30964C1.55469 2.95226 1.94922 3.1808 7.13281 5.77008C7.78906 6.0981 9.09375 6.89398 10 6.88322Z" fill="#1364EE"/>
                    </svg>
                    <p className="px-[10px] py-[10px] md:py-0">kontakt@adwokat-staciwa.pl</p>
                </div>
                <div className="flex items-center justify-center flex-col text-center md:flex-row md:text-left">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M10 0.799805C4.47581 0.799805 0 5.05182 0 10.2998C0 15.5478 4.47581 19.7998 10 19.7998C15.5242 19.7998 20 15.5478 20 10.2998C20 5.05182 15.5242 0.799805 10 0.799805ZM12.3024 14.2109L8.74597 11.7555C8.62097 11.6673 8.54839 11.5294 8.54839 11.3839V4.9369C8.54839 4.68408 8.76613 4.47722 9.03226 4.47722H10.9677C11.2339 4.47722 11.4516 4.68408 11.4516 4.9369V10.2117L14.0121 11.9815C14.2298 12.1309 14.2742 12.4182 14.1169 12.625L12.9798 14.1113C12.8226 14.3143 12.5202 14.3603 12.3024 14.2109Z" fill="#1364EE"/>
                    </svg>
                    <p className="px-[10px] py-[10px] md:py-0">Pracujemy w godzinach: 09:00 - 17:00</p>
                </div>
                <div className="flex items-center justify-center flex-col text-center md:flex-row md:text-left">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <g clip-path="url(#clip0_157_3402)">
                            <path d="M19.4293 14.1325L15.0543 12.2575C14.8674 12.1778 14.6597 12.161 14.4624 12.2096C14.2652 12.2582 14.089 12.3696 13.9605 12.527L12.023 14.8942C8.98232 13.4605 6.53524 11.0134 5.10156 7.97269L7.46875 6.03519C7.62644 5.90694 7.73804 5.73081 7.78668 5.53345C7.83531 5.3361 7.81832 5.12828 7.73828 4.94144L5.86328 0.566443C5.77543 0.36504 5.62007 0.200601 5.42397 0.101481C5.22787 0.00236139 5.00332 -0.0252267 4.78906 0.0234739L0.726562 0.960974C0.519988 1.00868 0.335682 1.12499 0.203725 1.29093C0.0717677 1.45687 -4.75863e-05 1.66264 2.36571e-08 1.87465C2.36571e-08 11.8942 8.12109 19.9996 18.125 19.9996C18.3371 19.9998 18.5429 19.928 18.709 19.796C18.875 19.6641 18.9913 19.4797 19.0391 19.2731L19.9766 15.2106C20.025 14.9953 19.9968 14.7698 19.8969 14.5731C19.797 14.3763 19.6317 14.2205 19.4293 14.1325Z" fill="#1364EE"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_157_3402">
                                <rect width="20" height="20" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
                    <p className="px-[10px] py-[10px] md:py-0">+48 604 762 895</p>
                </div>
            </div>
            
            
            <div className="py-[60px] grid grid-cols-1 lg:grid-cols-2 gap-[40px]">
                <div className="p-[55px] bg-[#F7F7F7] rounded-[26px]">
                    <input type="text" placeholder="Imię i nazwisko" className="w-full h-[60px] px-[30px] my-[10px] border border-[#2f2f2fb3] rounded-[13px]"/>
                    <input type="text" placeholder="Imię i nazwisko" className="w-full h-[60px] px-[30px] my-[10px] border border-[#2f2f2fb3] rounded-[13px]"/>
                    <input type="text" placeholder="Imię i nazwisko" className="w-full h-[60px] px-[30px] my-[10px] border border-[#2f2f2fb3] rounded-[13px]"/>
                    <textarea placeholder="Wiadomość" className="min-h-[150px] max-h-[150px] w-full p-[30px] my-[10px] border border-[#2f2f2fb3] rounded-[13px]"/>
                    <p className="text-[12px] text-[#2f2f2fb3] py-[15px]">Wypełniając i przesyłając formularz niniejszym wyraża Pan/Pani zgodę na przetwarzanie swoich danych osobowych przez Centrum Nurkowe Aqua Aga jako administratora danych osobowych oraz akceptuje Pan/Pani regulamin zawarty na stronie www.</p>

                    <div className="flex items-center justify-end py-[35px]">
                        <button className="px-[30px] py-[18px] bg-[#123D21] rounded-[3px] text-[#FFF] text-[16px] font-semibold font-poppins leading-[137%]">
                            Wyślij wiadomość
                        </button>
                    </div>

                </div>

                <div className="bg-[#F7F7F7] rounded-[26px] overflow-hidden min-h-[600px]">
                    <iframe
                        title="Mapa firmy"
                        src={mapUrl}
                        width="100%"
                        height="100%"
                        frameBorder="0"
                        style={{ border: 0 }}
                        allowFullScreen
                    />
                </div>
            </div>

        </div>
    )
}

export default Contact;